import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    Typography
} from '@material-ui/core'
import { DateTimeFormatter, components } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import LabelValueVertical from './LabelValueVertical'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import Utils from 'src/views/common/utils/Utils'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

// import useFetchCountryCodes from 'src/views/vesselschedule/searchschedule/useFetchCountryCodes'


const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngDateField,
            CngDateTimeField
        },
    },
    CngGridItem,
} = components

const {
    CngCodeMasterAutocompleteField,
} = components.form.field

const FormBody = (props) => {

    const {
        // countryCodeByPortCodeRef,
        isCarrierAmend,
        isShipperAmend,
        forceExpand,
        isCreateBooking
    } = props

    const translatedTextsObject = FreightBookingTranslationText()
    const [shipmentTypeExpanded, setShipmentTypeExpanded] = useState(false)
    // const countryCodeByPortCodeRef = useRef([])

    const [placeOfReceiptCodeField, , { setValue: setPlaceOfReceiptCodeField }] = useField('placeOfReceiptCode')
    const [, ,] = useField('placeOfDeliveryCode')
    const [, , { setValue: setPolCountryCodeField }] = useField('polCountryCode')
    const [, , { setValue: setPodCountryCodeField }] = useField('podCountryCode')
    const [placeOfReceiptField, ,] = useField('placeOfReceipt')
    const [placeOfDeliveryField, ,] = useField('placeOfDelivery')
    const [finalDestField, ,] = useField('finalDest')
    const [destCodeField, ,] = useField('destCode')
    const [shipperRemarksField, ,] = useField('shipperRemarks')
    const [cargoReadyDateField, , { setValue: setCargoReadyDateField }] = useField('cargoReadyDate')
    const [moveTypeField, ,] = useField('moveType')
    const [showEmptyPickup, , { setValue: setShowEmptyPickupField }] = useField('showEmptyPickup')
    const [showEmptyDropoff, , { setValue: setShowEmptyDropoffField }] = useField('showEmptyDropoff')
    const [showShipFrom, , { setValue: setShowShipFromField }] = useField('showShipFrom')
    const [showShipTo, , { setValue: setShowShipToField }] = useField('showShipTo')
    const [cargoDeliveryDateField, , { setValue: setCargoDeliveryDateField }] = useField('cargoDeliveryDate')
    const [placeOfReceiptEtdField, , { setValue: setPlaceOfReceiptEtdField }] = useField('placeOfReceiptEtd')
    const [, , { setValue: setPlaceOfDeliveryEtaField }] = useField('placeOfDeliveryEta')
    //const [isEmptyPickupCompNameFilled, setIsEmptyPickupCompNameFilled] = useState(false)
    const [moveTypeKey, setMoveTypeKey] = useState()

    const [isEmptyPickupFilled, , { setValue: setIsEmptyPickupFilled }] = useField('isEmptyPickupFilled')
    const [emptyPickupCompNameField, , { setValue: setEmptyPickupCompNameField }] = useField('emptyPickupCompName')
    const [emptyPickupCompAddressField, , { setValue: setEmptyPickupCompAddressField }] = useField('emptyPickupCompAddress')
    const [emptyPickupContactPhoneNoField, , { setValue: setEmptyPickupContactPhoneNoField }] = useField('emptyPickupContactPhoneNo')
    const [emptyPickupContactNameField, , { setValue: setEmptyPickupContactNameField }] = useField('emptyPickupContactName')
    const [emptyPickupDatePickupField, , { setValue: setEmptyPickupDatePickupField }] = useField('emptyPickupDatePickup')

    const [isEmptyDropoffFilled, , { setValue: setIsEmptyDropoffFilled }] = useField('isEmptyDropoffFilled')
    const [emptyDropoffCompNameField, , { setValue: setEmptyDropoffCompNameField }] = useField('emptyDropoffCompName')
    const [emptyDropoffCompAddressField, , { setValue: setEmptyDropoffCompAddressField }] = useField('emptyDropoffCompAddress')
    const [emptyDropoffContactPhoneNoField, , { setValue: setEmptyDropoffContactPhoneNoField }] = useField('emptyDropoffContactPhoneNo')
    const [emptyDropoffContactNameField, , { setValue: setEmptyDropoffContactNameField }] = useField('emptyDropoffContactName')
    const [emptyDropoffDateDropoffField, , { setValue: setEmptyDropoffDateDropoffField }] = useField('emptyDropoffDateDropoff')

    const [, , { setValue: setShipFromCompNameField }] = useField('shipFromCompName')
    const [, , { setValue: setShipFromCompAddressField }] = useField('shipFromCompAddress')
    const [, , { setValue: setShipFromContactNameField }] = useField('shipFromContactName')
    const [, , { setValue: setShipFromContactPhoneNoField }] = useField('shipFromContactPhoneNo')
    const [, , { setValue: setShipFromDatePickupField }] = useField('shipFromDatePickup')
    const [, , { setValue: setShipFromDatePositioningField }] = useField('shipFromDatePositioning')

    const [, , { setValue: setShipToCompNameField }] = useField('shipToCompName')
    const [, , { setValue: setShipToCompAddressField }] = useField('shipToCompAddress')
    const [, , { setValue: setShipToContactNameField }] = useField('shipToContactName')
    const [, , { setValue: setShipToContactPhoneNoField }] = useField('shipToContactPhoneNo')
    const [, , { setValue: setShipToDateDeliveryField }] = useField('shipToDateDelivery')

    useEffect(() => {

        if (moveTypeField != null) {
            setShowHideField(moveTypeField.value)
        }
        var addDays = 30;

        var cargoReadyDate = moment().format(Utils.UI_FORMAT_DATE)
        var cargoDeliveryDate = moment(
            new Date(
                new Date().setTime(new Date().getTime() + addDays * 24 * 60 * 60 * 1000)
            )
        ).format(Utils.UI_FORMAT_DATE);

        var placeOfReceiptEtd = moment(cargoReadyDate).format(Utils.UI_FORMAT_DATE_TIME);
        var PlaceOfDeliveryEta = moment(cargoDeliveryDate).format(Utils.UI_FORMAT_DATE_TIME);

        if (!props.isEdit) {
            setCargoReadyDateField(cargoReadyDate)
            setCargoDeliveryDateField(cargoDeliveryDate)
            setPlaceOfDeliveryEtaField(PlaceOfDeliveryEta)
            setPlaceOfReceiptEtdField(placeOfReceiptEtd)
        }
        setMoveTypeKey(uuid())
        setIsEmptyPickupFilled(checkIsEmptyPickupFilled())
        setIsEmptyDropoffFilled(checkIsEmptyDropoffFilled())

    }, [props.isEdit, props.moveTypeKeyRef, props.forceExpand])

    function setShowHideField(moveType) {
        console.log("setShowHideField" + moveType)
        console.log(moveTypeField)

        let isEmptyPartyShown = (moveType === CalistaConstant.MOVE_TYPE_PORT_TO_PORT || moveType === CalistaConstant.MOVE_TYPE_PORT_TO_DOOR)
        let isShipFromShown = (moveType === CalistaConstant.MOVE_TYPE_DOOR_TO_PORT || moveType === CalistaConstant.MOVE_TYPE_DOOR_TO_DOOR)
        let isShipToShown = (moveType === CalistaConstant.MOVE_TYPE_PORT_TO_DOOR || moveType === CalistaConstant.MOVE_TYPE_DOOR_TO_DOOR)
        setShowEmptyPickupFieldAndEmptyValue(isEmptyPartyShown)
        setShowEmptyDropoffFieldAndEmptyValue(isEmptyPartyShown)

        setShowShipFromFieldAndEmptyValue(isShipFromShown)

        setShowShipToFieldAndEmptyValue(isShipToShown)

        //auto expand the shipment section when ship from or ship to is shown

        if ((isShipFromShown || isShipToShown) || forceExpand || isCarrierAmend) {
            setShipmentTypeExpanded(true)
        }
        //if user switch back to ptp, no need to keep the shipment field expanded
        // else {

        //     setShipmentTypeExpanded(false)
        // }
    }


    function setShowShipToFieldAndEmptyValue(showField) {
        setShowShipToField(showField)
        if (!showField) {
            setShipToCompNameField(initialValues.shipToCompName)
            setShipToCompAddressField(initialValues.shipToCompAddress)
            setShipToContactNameField(initialValues.shipToContactName)
            setShipToContactPhoneNoField(initialValues.shipToContactPhoneNo)
            setShipToContactPhoneNoField(initialValues.shipToDateDelivery)
        }

    }

    function setShowShipFromFieldAndEmptyValue(showField) {
        setShowShipFromField(showField)
        if (!showField) {
            setShipFromCompNameField(initialValues.shipFromCompName)
            setShipFromCompAddressField(initialValues.shipFromCompAddress)
            setShipFromContactNameField(initialValues.shipFromContactName)
            setShipFromContactPhoneNoField(initialValues.shipFromContactPhoneNo)
            setShipFromDatePickupField(initialValues.shipFromDatePickup)
            setShipFromDatePositioningField(initialValues.shipFromDatePositioning)
        }

    }

    function setShowEmptyPickupFieldAndEmptyValue(showField) {

        setShowEmptyPickupField(showField)

        if (!showField) {
            //remove the value
            setEmptyPickupCompNameField(initialValues.emptyPickupCompName)
            setEmptyPickupDatePickupField(initialValues.emptyPickupDatePickup)
            setEmptyPickupCompAddressField(initialValues.emptyPickupCompAddress)
            setEmptyPickupContactNameField(initialValues.emptyPickupContactName)
            setEmptyPickupContactPhoneNoField(initialValues.emptyPickupContactPhoneNo)

        }
    }

    function setShowEmptyDropoffFieldAndEmptyValue(showField) {

        setShowEmptyDropoffField(showField)

        if (!showField) {
            //remove the value
            setEmptyDropoffCompNameField(initialValues.emptyDropoffCompName)
            setEmptyDropoffDateDropoffField(initialValues.emptyDropoffDateDropoff)
            setEmptyDropoffCompAddressField(initialValues.emptyDropoffCompAddress)
            setEmptyDropoffContactNameField(initialValues.emptyDropoffContactName)
            setEmptyDropoffContactPhoneNoField(initialValues.emptyDropoffContactPhoneNo)

        }
    }

    const handleShipmentExpand = () => {
        setShipmentTypeExpanded(!shipmentTypeExpanded);
        if (!isCarrierAmend) {
            //if carrier is handling manual expand/collapse, use movetype key to control it, no need use forceExpand
            props.setForceExpand(false)
        }
    };

    const onChangePlaceOfReceiptAutoComplete = (value, option) => {
        if (value != null) {
            // setPolCountryCodeField(countryCodeByPortCodeRef.current[value])
            props.setPlaceOfReceiptField(option.label)
        }
    }
    const onChangeplaceOfDeliveryAutoComplete = (value, option) => {
        if (value != null) {
            //  setPodCountryCodeField(countryCodeByPortCodeRef.current[value])
            props.setPlaceOfDeliveryField(option.label)
        }
    }

    function onMoveTypeChange(e) {

        setShowHideField(e)

        handleEmptyPickupCompanyChange(e)
        handleEmptyDropoffCompanyChange(e)

    }

    function checkIsEmptyPickupFilled() {
        console.log("checkIsEmptyPickupFilled")

        var emptyPickupInfo = Utils.isEmptyString(emptyPickupContactNameField.value)
            && Utils.isEmptyString(emptyPickupContactPhoneNoField.value)
            && Utils.isEmptyString(emptyPickupCompNameField.value)
            && Utils.isEmptyString(emptyPickupCompAddressField.value)
        //&& Utils.isEmptyString(emptyPickupDatePickup.value)

        console.log(emptyPickupDatePickupField)

        return !emptyPickupInfo
    }

    function checkIsEmptyDropoffFilled() {
        let emptyDropoffInfo = Utils.isEmptyString(emptyDropoffContactNameField.value)
            && Utils.isEmptyString(emptyDropoffContactPhoneNoField.value)
            && Utils.isEmptyString(emptyDropoffCompNameField.value)
            && Utils.isEmptyString(emptyDropoffCompAddressField.value)
        return !emptyDropoffInfo
    }

    const handleEmptyPickupCompanyChange = (evt) => {

        setIsEmptyPickupFilled(checkIsEmptyPickupFilled() || !Utils.isEmptyString(emptyPickupDatePickupField.value))
    };

    const handleEmptyDropoffCompanyChange = (evt) => {

        setIsEmptyDropoffFilled(checkIsEmptyDropoffFilled() || !Utils.isEmptyString(emptyDropoffDateDropoffField.value))
    };

    const onEmptyPickupDateChange = (evt) => {

        let date = Utils.formatDate(evt, Utils.UI_FORMAT_DATE_TIME, "")

        setEmptyPickupDatePickupField(date)

        setIsEmptyPickupFilled(checkIsEmptyPickupFilled() || evt != null)
    };

    const onEmptyDropoffDateChange = (evt) => {

        let date = Utils.formatDate(evt, Utils.UI_FORMAT_DATE_TIME, "")

        setEmptyDropoffDateDropoffField(date)

        setIsEmptyDropoffFilled(checkIsEmptyDropoffFilled() || evt != null)
    };

    return (
        <Card>
            <Accordion expanded={shipmentTypeExpanded}>
                <AccordionSummary aria-controls="shipmentComponentContent" id='shipmentComponentHeader'>
                    <Grid container>
                        <AccordionHeaderComponent title={translatedTextsObject.shipment} />
                        <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={4} sm={4}>
                                <CngCodeMasterAutocompleteField
                                    name='moveType'
                                    label={translatedTextsObject.moveType}
                                    isRequired
                                    codeType='CAL_BK_MOVE_TYPE'
                                    onChange={onMoveTypeChange}
                                    key={moveTypeKey}
                                />

                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <PortSelectAutoComplete
                                    name='placeOfReceiptCode'
                                    label={translatedTextsObject.placeOfReceipt}
                                    required
                                    keyRef={props.placeOfReceiptKeyRef}
                                    value={props.placeOfReceiptCodeField.value}
                                    onChangeAutoComplete={onChangePlaceOfReceiptAutoComplete}
                                />

                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <PortSelectAutoComplete
                                    name='placeOfDeliveryCode'
                                    label={translatedTextsObject.placeOfDelivery}
                                    required
                                    keyRef={props.placeOfDeliveryKeyRef}
                                    value={props.placeOfDeliveryCodeField.value}
                                    onChangeAutoComplete={onChangeplaceOfDeliveryAutoComplete}
                                />
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                {/* {isCarrierAmend ? (
                                    <Box className="view-card-content">
                                        <Box className="view-card-content-label">
                                            {translatedTextsObject.cargoReadyDate}
                                        </Box>
                                        <Box className="view-card-content-value">
                                            {Utils.formatDate(cargoReadyDateField.value, Utils.FORMAT_DATE, Utils.DASH)}
                                        </Box>
                                    </Box>
                                ) : ( */}
                                <CngDateField
                                    label={translatedTextsObject.cargoReadyDate}
                                    name='cargoReadyDate'
                                    format={Utils.UI_FORMAT_DATE}
                                    disabled={isCarrierAmend || isShipperAmend}
                                    // shouldDisableDate={(date) => {
                                    //     return moment(moment().format(Utils.UI_FORMAT_DATE)).isAfter(
                                    //         moment(date)
                                    //     )
                                    // }}
                                    isRequired
                                    onChange={(event, x) => { props.CargoReadyDateChange(x, "cargoReadyDate") }}
                                />
                                {/* )}  */}
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                {/* {isCarrierAmend ? (
                                <Box className="view-card-content">
                                    <Box className="view-card-content-label">
                                        {translatedTextsObject.cargoReadyDate}
                                    </Box>
                                    <Box className="view-card-content-value">
                                        {Utils.formatDate(cargoDeliveryDateField.value, Utils.FORMAT_DATE, Utils.DASH)}
                                    </Box>
                                </Box>
                            ) :( */}
                                <CngDateField
                                    label={translatedTextsObject.cargoDeliveryDate}
                                    name='cargoDeliveryDate'
                                    format={Utils.UI_FORMAT_DATE}
                                    disabled={isCarrierAmend || isShipperAmend}
                                    shouldDisableDate={(date) => {
                                        return moment(moment(cargoReadyDateField.value).format(Utils.UI_FORMAT_DATE)).isAfter(
                                            moment(date)
                                        )
                                    }}
                                    isRequired
                                    onChange={(event, x) => { props.CargoDeliveryDateChange(x, "cargoDeliveryDate") }}
                                />
                                {/* )} */}

                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                        </Grid>
                    </Grid>

                </AccordionSummary>

                <AccordionDetails>
                    <Grid container>
                        <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                {/* {isCarrierAmend ? (
                                <LabelValueVertical label={translatedTextsObject.placeOfReceiptDesc} 
                                                    value={placeOfReceiptField.value}/>
                            ) : ( */}
                                <CngTextField
                                    type='text'
                                    name='placeOfReceipt'
                                    inputProps={{ maxLength: 35 }}
                                    label={translatedTextsObject.placeOfReceiptDesc}
                                    disabled={isCarrierAmend}
                                />
                                {/* )} */}
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4} >
                                {/* {isCarrierAmend ? (
                                <LabelValueVertical label={translatedTextsObject.placeOfDeliveryDesc} 
                                                    value={placeOfDeliveryField.value}/>
                            ) : ( */}
                                <CngTextField
                                    type='text'
                                    name='placeOfDelivery'
                                    inputProps={{ maxLength: 35 }}
                                    label={translatedTextsObject.placeOfDeliveryDesc}
                                    disabled={isCarrierAmend}
                                />
                                {/* )}  */}
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngDateTimeField
                                    label={translatedTextsObject.placeOfReceiptETD}
                                    name='placeOfReceiptEtd'
                                    disabled={isShipperAmend}
                                    isRequired={isCarrierAmend}
                                    format={Utils.UI_FORMAT_DATE_TIME}
                                    shouldDisableDate={(date) => {
                                        return moment(moment().format(Utils.UI_FORMAT_DATE)).isAfter(
                                            moment(date)
                                        )
                                    }} />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngDateTimeField
                                    label={translatedTextsObject.placeOfDeliveryETA}
                                    name='placeOfDeliveryEta'
                                    format={Utils.UI_FORMAT_DATE_TIME}
                                    disabled={isShipperAmend}
                                    isRequired={isCarrierAmend}
                                    shouldDisableDate={(date) => {
                                        return moment(moment(placeOfReceiptEtdField.value).format(Utils.UI_FORMAT_DATE)).isAfter(
                                            moment(date)
                                        )
                                    }} />
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                {/* {isCarrierAmend ? (
                                <LabelValueVertical label={translatedTextsObject.destinationCode} 
                                                    value={destCodeField.value}/>
                            ) : ( */}
                                <CngTextField
                                    type='text'
                                    name='destCode'
                                    inputProps={{ maxLength: 10 }}
                                    label={translatedTextsObject.destinationCode}
                                    disabled={isCarrierAmend}
                                />
                                {/* )} */}
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                {/* {isCarrierAmend ? (
                                <LabelValueVertical label={translatedTextsObject.finalDestination} 
                                                    value={finalDestField.value}/>
                            ) : ( */}
                                <CngTextField
                                    type='text'
                                    name='finalDest'
                                    inputProps={{ maxLength: 100 }}
                                    label={translatedTextsObject.finalDestination}
                                    disabled={isCarrierAmend}
                                />
                                {/* )} */}
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                {!isCreateBooking &&
                                    <CngDateTimeField
                                        label={translatedTextsObject.ladenCutoffDate}
                                        name='ladenCutoffDate'
                                        format={Utils.UI_FORMAT_DATE_TIME}
                                        disabled={isShipperAmend}
                                    />
                                }
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                {!isCreateBooking &&
                                    <CngDateTimeField
                                        label={translatedTextsObject.siCutoffDate}
                                        name='siCutoffDate'
                                        format={Utils.UI_FORMAT_DATE_TIME}
                                        disabled={isShipperAmend} />
                                }
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                {!isCreateBooking &&
                                    <CngDateTimeField
                                        label={translatedTextsObject.evgmCutoffDate}
                                        name='evgmCutoffDate'
                                        format={Utils.UI_FORMAT_DATE_TIME}
                                        disabled={isShipperAmend} />
                                }
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={4} sm={4}></CngGridItem>
                            <CngGridItem xs={8} sm={8}>
                                {/* {isCarrierAmend ? (
                                <LabelValueVertical label={translatedTextsObject.shipperRemarks} 
                                                    value={shipperRemarksField.value}/>
                            ) : ( */}
                                <CngTextField
                                    type='text'
                                    name='shipperRemarks'
                                    label={translatedTextsObject.shipperRemarks}
                                    multiline
                                    rows={4}
                                    inputProps={{ maxLength: 1024 }}
                                    disabled={isCarrierAmend}
                                />
                                {/* )} */}
                            </CngGridItem>
                        </Grid>


                        <div style={{ display: (showEmptyPickup.value) ? "inline" : "none", paddingTop: 55 }} >
                            <Card className="inner-card-group">
                                <Grid container xs={12} sm={12} spacing={2} >
                                    <CngGridItem xs={4} sm={4}>
                                        <Typography className="page-content-groupLabel">
                                            {translatedTextsObject.emptyPickup}
                                        </Typography>
                                        <Typography className="greyLabel2">
                                            {translatedTextsObject.emptyPickupInstruction}
                                        </Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            isRequired={isEmptyPickupFilled.value}
                                            type='text'
                                            name='emptyPickupCompName'
                                            inputProps={{ maxLength: 200 }}
                                            label={translatedTextsObject.compName}
                                            onKeyUp={handleEmptyPickupCompanyChange} />


                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4} >
                                        <CngTextField
                                            type='text'
                                            name='emptyPickupCompAddress'
                                            inputProps={{ maxLength: 512 }}
                                            label={translatedTextsObject.compAddress}
                                            multiline
                                            isRequired={isEmptyPickupFilled.value}
                                            onKeyUp={handleEmptyPickupCompanyChange}
                                            rows={4}
                                        />



                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='emptyPickupContactName'
                                            inputProps={{ maxLength: 70 }}
                                            label={translatedTextsObject.ContactName}
                                            onKeyUp={handleEmptyPickupCompanyChange} />

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='emptyPickupContactPhoneNo'
                                            inputProps={{ maxLength: 50 }}
                                            label={translatedTextsObject.ContactPhoneNo}
                                            onKeyUp={handleEmptyPickupCompanyChange} />

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngDateTimeField
                                            label={translatedTextsObject.emptyPickupDate}
                                            name='emptyPickupDatePickup'
                                            format={Utils.UI_FORMAT_DATE_TIME}
                                            onChange={(evt) => onEmptyPickupDateChange(evt)}
                                        // onKeyUp={handleEmptyPickupCompanyChange}
                                        />

                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4} style={{ visibility: "hidden" }}>
                                        <CngTextField
                                            type='text'
                                            name='filling'
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Card>

                        </div>

                        {isCarrierAmend ? <div style={{ display: (showEmptyDropoff.value) ? "inline" : "none", paddingTop: 55 }} >
                            <Card className="inner-card-group">
                                <Grid container xs={12} sm={12} spacing={2} >
                                    <CngGridItem xs={4} sm={4}>
                                        <Typography className="page-content-groupLabel">
                                            {translatedTextsObject.emptyDropoff}
                                        </Typography>
                                        <Typography className="greyLabel2">
                                            {translatedTextsObject.emptyDropoffInstruction}
                                        </Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            isRequired={isEmptyDropoffFilled.value}
                                            type='text'
                                            name='emptyDropoffCompName'
                                            inputProps={{ maxLength: 200 }}
                                            label={translatedTextsObject.compName}
                                            onKeyUp={handleEmptyDropoffCompanyChange}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4} >
                                        <CngTextField
                                            type='text'
                                            name='emptyDropoffCompAddress'
                                            inputProps={{ maxLength: 512 }}
                                            label={translatedTextsObject.compAddress}
                                            multiline
                                            isRequired={isEmptyDropoffFilled.value}
                                            onKeyUp={handleEmptyDropoffCompanyChange}
                                            rows={4}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='emptyDropoffContactName'
                                            inputProps={{ maxLength: 70 }}
                                            label={translatedTextsObject.ContactName}
                                            onKeyUp={handleEmptyDropoffCompanyChange} />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='emptyDropoffContactPhoneNo'
                                            inputProps={{ maxLength: 50 }}
                                            label={translatedTextsObject.ContactPhoneNo}
                                            onKeyUp={handleEmptyDropoffCompanyChange} />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngDateTimeField
                                            label={translatedTextsObject.emptyDropoffDate}
                                            name='emptyDropoffDateDropoff'
                                            format={Utils.UI_FORMAT_DATE_TIME}
                                            onChange={(evt) => onEmptyDropoffDateChange(evt)}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4} style={{ visibility: "hidden" }}>
                                        <CngTextField
                                            type='text'
                                            name='filling'
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Card>
                        </div> : <></>}

                        <div style={{ display: (showShipFrom.value) ? "inline" : "none", paddingTop: 55 }} >
                            <Card className="inner-card-group">
                                <Grid container xs={12} sm={12} spacing={2}>
                                    <CngGridItem xs={4} sm={4}>
                                        <Typography className="page-content-groupLabel">
                                            {translatedTextsObject.shipFrom}
                                        </Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            isRequired
                                            type='text'
                                            name='shipFromCompName'
                                            inputProps={{ maxLength: 200 }}
                                            label={translatedTextsObject.compName}
                                        />

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4} >
                                        <CngTextField
                                            isRequired
                                            type='text'
                                            name='shipFromCompAddress'
                                            inputProps={{ maxLength: 512 }}
                                            label={translatedTextsObject.compAddress}
                                            multiline
                                            rows={4}
                                        />

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}></CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='shipFromContactName'
                                            inputProps={{ maxLength: 70 }}
                                            label={translatedTextsObject.ContactName} />

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='shipFromContactPhoneNo'
                                            inputProps={{ maxLength: 50 }}
                                            label={translatedTextsObject.ContactPhoneNo} />

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}></CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngDateTimeField
                                            label={translatedTextsObject.shipFromDatePositioning}
                                            name='shipFromDatePositioning'
                                            format={Utils.UI_FORMAT_DATE_TIME} />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngDateTimeField
                                            label={translatedTextsObject.fullPickupDate}
                                            name='shipFromDatePickup'
                                            format={Utils.UI_FORMAT_DATE_TIME} />
                                    </CngGridItem>
                                </Grid>
                            </Card>
                        </div>

                        <div style={{ display: (showShipTo.value) ? "inline" : "none", paddingTop: 55 }} >
                            <Card className="inner-card-group">
                                <Grid container xs={12} sm={12} spacing={2} >
                                    <CngGridItem xs={4} sm={4}>
                                        <Typography className="page-content-groupLabel">
                                            {translatedTextsObject.shipTo}
                                        </Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            isRequired
                                            type='text'
                                            name='shipToCompName'
                                            inputProps={{ maxLength: 200 }}
                                            label={translatedTextsObject.compName}
                                        />

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4} >
                                        <CngTextField
                                            isRequired
                                            type='text'
                                            name='shipToCompAddress'
                                            inputProps={{ maxLength: 512 }}
                                            label={translatedTextsObject.compAddress}
                                            multiline
                                            rows={4}
                                        />

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}></CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='shipToContactName'
                                            inputProps={{ maxLength: 70 }}
                                            label={translatedTextsObject.ContactName} />

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngTextField
                                            type='text'
                                            name='shipToContactPhoneNo'
                                            inputProps={{ maxLength: 50 }}
                                            label={translatedTextsObject.ContactPhoneNo} />

                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}></CngGridItem>

                                    <CngGridItem xs={4} sm={4} style={{ visibility: "hidden" }}>
                                        <CngTextField
                                            type='text'
                                            name='filling'
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                        <CngDateTimeField
                                            label={translatedTextsObject.shipToDateDelivery}
                                            name='shipToDateDelivery'
                                            format={Utils.UI_FORMAT_DATE_TIME} />
                                    </CngGridItem>
                                </Grid>
                            </Card>
                        </div>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleShipmentExpand}
                expanded={shipmentTypeExpanded}
                footerText={translatedTextsObject.additionalShipmentDetails}
            />

        </Card >
    )
}

const initialValues = {
    moveType: CalistaConstant.MOVE_TYPE_PORT_TO_PORT,

    placeOfReceiptCode: '',
    placeOfDeliveryCode: '',
    placeOfReceipt: '',
    placeOfDelivery: '',
    cargoReadyDate: null,
    cargoDeliveryDate: null,
    placeOfReceiptEtd: null,
    placeOfDeliveryEta: null,
    ladenCutoffDate: null,
    siCutoffDate: null,
    evgmCutoffDate: null,
    destCode: '',
    finalDest: '',
    shipperRemarks: '',
    emptyPickupCompName: null,
    emptyPickupCompAddress: null,
    emptyPickupContactPhoneNo: '',
    emptyPickupContactName: '',
    emptyPickupDatePickup: null,

    emptyDropoffCompName: null,
    emptyDropoffCompAddress: null,
    emptyDropoffContactPhoneNo: '',
    emptyDropoffContactName: '',
    emptyDropoffDateDropoff: null,

    shipFromCompName: '',
    shipFromCompAddress: null,
    shipFromContactName: '',
    shipFromContactPhoneNo: '',
    shipFromDatePickup: null,
    shipFromDatePositioning: null,

    shipToCompName: '',
    shipToCompAddress: null,
    shipToContactName: '',
    shipToContactPhoneNo: '',
    shipToDateDelivery: null,

}

const ShipmentComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})


export default ShipmentComponent
