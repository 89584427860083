import { Box, Grid } from '@material-ui/core'

import ComponentWrapper from './ComponentWrapper'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import LabelValueVertical from './LabelValueVertical'
import React from 'react'
import Utils from 'src/views/common/utils/Utils'

const DropoffComponent = (props) => {
  const {
    label,
    dropOffCompanyName,
    dropOffCompanyAddress,
    dropOffContactName,
    dropOffContactPhoneNo,
    dropOffTimeLabel1,
    dropOffTimeLabel2,
    dropOffTime1,
    dropOffTime2,
    styleCompany,
    styleContact,
    styleTime1,
    styleTime2
  } = props
  const translatedTextsObject = FreightBookingTranslationText()

  return (
    <ComponentWrapper
      header={label}
      headerStyle='view-card-header-no-bg'
      cardStyle={{ margin: 20 }}
      backgroundStyle='inner-card-background'
    >
      <Grid container xs={12} sm={12} spacing={0}>
        <Grid item xs sm></Grid>
        <Grid item xs={4} sm={4}>
          <LabelValueVertical
            label={translatedTextsObject.company}
            value={
              <div>
                <Box fontWeight='bolder'>{dropOffCompanyName}</Box>
                <Box> {dropOffCompanyAddress ? dropOffCompanyAddress : Utils.DASH} </Box>
              </div>
            }
            boxStyle={styleCompany}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <LabelValueVertical
            label={translatedTextsObject.contact}
            value={
              <div>
                <Box fontWeight='bolder'>{dropOffContactName}</Box>
                <Box> {dropOffContactPhoneNo ? dropOffContactPhoneNo : Utils.DASH} </Box>
              </div>
            }
            boxStyle={styleContact}
          />
        </Grid>
      </Grid>

      <Grid container xs={12} sm={12} spacing={0}>
        <Grid item xs sm></Grid>
        {dropOffTime1 && (
          <Grid item xs={4} sm={4}>
            <LabelValueVertical
              label={dropOffTimeLabel1}
              value={Utils.formatDate(
                dropOffTime1,
                Utils.UI_FORMAT_DATE_TIME,
                Utils.DASH
              )}
              boxStyle={styleTime1}
            />
          </Grid>
        )}
        {dropOffTime2 && (
          <Grid item xs={4} sm={4}>
            <LabelValueVertical
              label={dropOffTimeLabel2}
              value={Utils.formatDate(
                dropOffTime2,
                Utils.UI_FORMAT_DATE_TIME,
                Utils.DASH
              )}
              boxStyle={styleTime2}
            />
          </Grid>
        )}
      </Grid>
    </ComponentWrapper>
  )
}

export default DropoffComponent
