import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Box,
  Typography
} from '@material-ui/core'
import { components } from 'cng-web-lib'
import React, { useState } from 'react'

import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'

import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import AuditTrailDualColumn from 'src/components/auditTrail/AuditTrailDualColumn'
import AuditTrailDualColumnPort from 'src/components/auditTrail/AuditTrailDualColumnPort'
import Utils from 'src/views/common/utils/Utils'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'

const {
  form: {
    field: { CngTextField, CngDateField }
  },
  CngGridItem
} = components

const AuditTrailSchedule = (props) => {
  const { booking, ports, codeMasterList } = props
  const tranObj = FreightBookingTranslationText()
  const [scheduleExpanded, setScheduleExpanded] = useState(false)
  const handleScheduleExpand = () => {
    setScheduleExpanded(!scheduleExpanded)
  }

  let NO_VALUE = 'No values'

  let emptyPickupComp = Utils.getAuditTrailDiffs(booking, [
    'emptyPickupCompName',
    'emptyPickupCompAddress'
  ])
  let emptyPickupContact = Utils.getAuditTrailDiffs(booking, [
    'emptyPickupContactName',
    'emptyPickupContactPhoneNo'
  ])

  let emptyDropoffComp = Utils.getAuditTrailDiffs(booking, [
    'emptyDropoffCompName',
    'emptyDropoffCompAddress'
  ])
  let emptyDropoffContact = Utils.getAuditTrailDiffs(booking, [
    'emptyDropoffContactName',
    'emptyDropoffContactPhoneNo'
  ])

  let shipToComp = Utils.getAuditTrailDiffs(booking, [
    'shipToCompName',
    'shipToCompAddress'
  ])
  let shipToContact = Utils.getAuditTrailDiffs(booking, [
    'shipToContactName',
    'shipToContactPhoneNo'
  ])

  let shipFromComp = Utils.getAuditTrailDiffs(booking, [
    'shipFromCompName',
    'shipFromCompAddress'
  ])
  let shipFromContact = Utils.getAuditTrailDiffs(booking, [
    'shipFromContactName',
    'shipFromContactPhoneNo'
  ])

  let showEmptyPickup = Utils.hasFieldsChanged(booking, [
    'emptyPickupCompName',
    'emptyPickupCompAddress',
    'emptyPickupContactName',
    'emptyPickupContactPhoneNo',
    'emptyPickupDatePickup',
    'emptyPickupDatePickup'
  ])

  let showEmptyDropoff = Utils.hasFieldsChanged(booking, [
    'emptyDropoffCompName',
    'emptyDropoffCompAddress',
    'emptyDropoffContactName',
    'emptyDropoffContactPhoneNo',
    'emptyDropoffDateDropoff',
    'emptyDropoffDateDropoff'
  ])

  let showShipTo = Utils.hasFieldsChanged(booking, [
    'shipToCompName',
    'shipToCompAddress',
    'shipToContactName',
    'shipToContactPhoneNo',
    'shipToDateDelivery',
    'shipToDateDelivery'
  ])
  let showShipFrom = Utils.hasFieldsChanged(booking, [
    'shipFromCompName',
    'shipFromCompAddress',
    'shipFromContactName',
    'shipFromContactPhoneNo',
    'shipFromDatePositioning',
    'shipFromDatePickup'
  ])

  const ContactField = (contactprops) => {
    const { contact, label } = contactprops
    console.log(contact)

    function parseEmptyValue(value) {
      return Utils.isEmpty(value) ? '' : value
    }
    let oldContactValue =
      Utils.isEmpty(contact.valueOld[0]) && Utils.isEmpty(contact.valueOld[1])
        ? NO_VALUE
        : parseEmptyValue(contact.valueOld[0]) +
        '\n' +
        parseEmptyValue(contact.valueOld[1])
    let newContactValue =
      Utils.isEmpty(contact.valueNew[0]) && Utils.isEmpty(contact.valueNew[1])
        ? NO_VALUE
        : parseEmptyValue(contact.valueNew[0]) +
        '\n' +
        parseEmptyValue(contact.valueNew[1])
    return (
      <Grid container xs={12} sm={12} spacing={0}>
        <CngGridItem xs={6} sm={6}>
          <Box className='view-card-content'>
            <LabelValueVertical
              label={label}
              value={oldContactValue}
              valueStyle={
                oldContactValue === NO_VALUE
                  ? 'greyLabel paddingLeft16'
                  : undefined
              }
            />
          </Box>
        </CngGridItem>
        <CngGridItem xs={6} sm={6}>
          <Box className='view-card-content'>
            <LabelValueVertical
              label={label}
              value={newContactValue}
              valueStyle={
                newContactValue === NO_VALUE
                  ? 'greyLabel paddingLeft16'
                  : undefined
              }
            />
          </Box>
        </CngGridItem>
      </Grid>
    )
  }
  return (
    <Card>
      <Accordion expanded={scheduleExpanded}>
        <AccordionSummary
          aria-controls='shipmentComponentContent'
          id='shipmentComponentHeader'
        >
          <Grid container>
            <AccordionHeaderComponent title={tranObj.shipment} noMandatory />
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container xs={12} sm={12}>
            <AuditTrailDualColumn
              object={booking}
              field={'moveType'}
              codeType={CalistaConstant.CAL_BK_MOVE_TYPE}
              codeMasterList={codeMasterList}
              label={tranObj.moveType}
            />
            <AuditTrailDualColumnPort
              object={booking}
              field={'placeOfReceiptCode'}
              ports={ports}
              label={tranObj.placeOfReceipt}
            />
            <AuditTrailDualColumnPort
              object={booking}
              field={'placeOfDeliveryCode'}
              ports={ports}
              label={tranObj.placeOfDelivery}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'cargoReadyDate'}
              timestamp
              label={tranObj.cargoReadyDate}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'cargoDeliveryDate'}
              timestamp
              label={tranObj.cargoDeliveryDate}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'placeOfReceipt'}
              label={tranObj.placeOfReceiptDesc}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'placeOfDelivery'}
              label={tranObj.placeOfDeliveryDesc}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'placeOfReceiptEtd'}
              timestamp
              label={tranObj.placeOfReceiptETD}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'placeOfDeliveryEta'}
              timestamp
              label={tranObj.placeOfDeliveryETA}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'evgmCutoffDate'}
              timestamp
              label={tranObj.evgmCutoffDate}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'ladenCutoffDate'}
              timestamp
              label={tranObj.ladenCutoffDate}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'siCutoffDate'}
              timestamp
              label={tranObj.siCutoffDate}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'destCode'}
              label={tranObj.destinationCode}
            />
            <AuditTrailDualColumn
              object={booking}
              field={'finalDest'}
              label={tranObj.finalDestination}
            />
            {/* <AuditTrailDualColumn object={booking} field={'shpRemarks'} label = {tranObj.shipperRemarks} />    
                    <AuditTrailDualColumn object={booking} field={'carRemarks'} label = {tranObj.carrierRemarks} /> */}
            {
              /* Empty pickup */
              showEmptyPickup && (
                <>
                  <Typography
                    variant='h5'
                    className='font-bolder paddingLeft16 paddingTop16'
                  >
                    {tranObj.emptyPickup}
                  </Typography>
                  {Utils.hasChanges(emptyPickupComp) && (
                    <ContactField
                      contact={emptyPickupComp}
                      label={tranObj.company}
                    />
                  )}
                  {Utils.hasChanges(emptyPickupContact) && (
                    <ContactField
                      contact={emptyPickupContact}
                      label={tranObj.contact}
                    />
                  )}
                  <AuditTrailDualColumn
                    object={booking}
                    field={'emptyPickupDatePickup'}
                    timestamp
                    label={tranObj.emptyPickupDateTime}
                  />
                </>
              )
            }
            {
              /* Empty dropoff */
              showEmptyDropoff && (
                <>
                  <Typography
                    variant='h5'
                    className='font-bolder paddingLeft16 paddingTop16'
                  >
                    {tranObj.emptyDropoff}
                  </Typography>
                  {Utils.hasChanges(emptyDropoffComp) && (
                    <ContactField
                      contact={emptyDropoffComp}
                      label={tranObj.company}
                    />
                  )}
                  {Utils.hasChanges(emptyDropoffContact) && (
                    <ContactField
                      contact={emptyDropoffContact}
                      label={tranObj.contact}
                    />
                  )}
                  <AuditTrailDualColumn
                    object={booking}
                    field={'emptyDropoffDateDropoff'}
                    timestamp
                    label={tranObj.emptyDropoffDateTime}
                  />
                </>
              )
            }
            {
              /* Ship from*/
              showShipFrom && (
                <>
                  <Typography
                    variant='h5'
                    className='font-bolder paddingLeft16 paddingTop16'
                  >
                    {tranObj.shipFrom}
                  </Typography>
                  {Utils.hasChanges(shipFromComp) && (
                    <ContactField
                      contact={shipFromComp}
                      label={tranObj.company}
                    />
                  )}
                  {Utils.hasChanges(shipFromContact) && (
                    <ContactField
                      contact={shipFromContact}
                      label={tranObj.contact}
                    />
                  )}
                  <AuditTrailDualColumn
                    object={booking}
                    field={'shipFromDatePositioning'}
                    timestamp
                    label={tranObj.shipFromDatePositioning}
                  />
                  <AuditTrailDualColumn
                    object={booking}
                    field={'shipFromDatePickup'}
                    timestamp
                    label={tranObj.fullPickupDate}
                  />
                </>
              )
            }
            {
              /* Ship To */
              showShipTo && (
                <>
                  <Typography
                    variant='h5'
                    className='font-bolder paddingLeft16 paddingTop16'
                  >
                    {tranObj.shipTo}
                  </Typography>
                  {Utils.hasChanges(shipToComp) && (
                    <ContactField
                      contact={shipToComp}
                      label={tranObj.company}
                    />
                  )}
                  {Utils.hasChanges(shipToContact) && (
                    <ContactField
                      contact={shipToContact}
                      label={tranObj.contact}
                    />
                  )}
                  <AuditTrailDualColumn
                    object={booking}
                    field={'shipToDateDelivery'}
                    timestamp
                    label={tranObj.shipToDateDelivery}
                  />
                </>
              )
            }
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AccordionFooterControlComponent
        handleAccordionExpand={handleScheduleExpand}
        expanded={scheduleExpanded}
        footerText={tranObj.showShipmentDetails}
        footerTextExpanded={tranObj.hideShipmentDetails}
        noOptionalFields
      />
    </Card>
  )
}

export default AuditTrailSchedule
