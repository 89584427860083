import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComponentWrapper from './ComponentWrapper'
import FreightBookingTranslationText from '../shared/FreightBookingTranslationText'
import LabelValueVertical from './LabelValueVertical'
import PickupDetailComponent from './PickupDetailComponent'
import DropoffDetailComponent from './DropoffDetailComponent'
import PortViewField from 'src/components/field/PortViewField'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import apiParser from '../shared/apiParser'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const FormBody = (props) => {
  const { shipmentData, showDetails, isCarrier, ports, auditBooking } = props
  const translatedTextsObject = FreightBookingTranslationText()
  // const [moveTypeField, , { setValue: setMoveTypeField }] = useField('moveType')
  const moveType = shipmentData.moveType
  const [placeOfReceiptCodeField, , { setValue: setPlaceOfReceiptCdeField }] =
    useField('placeOfReceiptCode')
  const [
    placeOfDeliveryCodeField,
    ,
    { setValue: setPlaceOfDeliveryCodeField }
  ] = useField('placeOfDeliveryCode')

  const placeOfReceiptKeyRef = useRef(uuid())
  const placeOfDeliveryKeyRef = useRef(uuid())
  const moveTypeKeyRef = useRef(uuid())

  const { setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    // setMoveTypeField(shipmentData.moveType)
    setPlaceOfReceiptCdeField(shipmentData.placeOfReceiptCode)
    setPlaceOfDeliveryCodeField(shipmentData.placeOfDeliveryCode)

    placeOfReceiptKeyRef.current = uuid()
    placeOfDeliveryKeyRef.current = uuid()
    moveTypeKeyRef.current = uuid()

    setFieldValue('moveType', shipmentData.moveType)

    setShouldRender(true)
  }, [])

  if (!shouldRender) {
    return null
  }

  return (
    <Grid container xs={12} sm={12}>
      <CngGridItem xs={12} sm={12}>
        <Card>
          <Box className='view-card-header'>
            {translatedTextsObject.shipment}
          </Box>
          <Box>
            <ComponentWrapper cardStyle={{ margin: 20 }}>
              <Grid container xs={12} sm={12}>
                <CngGridItem xs={3} sm={3}>
                  <Box
                    className='view-card-content'
                    style={{
                      ...Utils.auditHighLight(auditBooking, ['moveType']),
                      display: 'inline-block'
                    }}
                  >
                    <Box className='view-card-content-label'>
                      {translatedTextsObject.moveType}
                    </Box>
                    <Box className='view-card-content-value'>
                      <CodeMasterViewField
                        codeType='CAL_BK_MOVE_TYPE'
                        code={shipmentData.moveType}
                      />
                    </Box>
                  </Box>
                </CngGridItem>
                <CngGridItem xs={12} sm={9}>
                  <Grid container style={{ paddingRight: 20 }}>
                    <CngGridItem xs={12} sm={5}>
                      <Box
                        className='view-card-content'
                        style={Utils.auditHighLight(auditBooking, [
                          'placeOfReceiptCode'
                        ])}
                      >
                        <Box className='view-card-content-label'>
                          {translatedTextsObject.placeOfReceipt}
                        </Box>
                        <Box
                          className='view-card-content-value'
                          style={{ paddingRight: 0 }}
                        >
                          {/* using placeOfReceiptCodeField.value will cause data render issue due to initial field value is empty */}
                          {/* <PortViewField portCode={placeOfReceiptCodeField.value} /> */}
                          <PortViewField
                            portCode={shipmentData.placeOfReceiptCode}
                          />
                        </Box>
                      </Box>
                    </CngGridItem>

                    <CngGridItem xs sm>
                      <Grid
                        container
                        justify='center'
                        alignItems='center'
                        style={{ height: '100%' }}
                      >
                        {/* <Box className="view-card-content">
                                                <Box className="view-card-content-value" style={{ paddingLeft: 30, paddingRight: 0 }}> 
                                                <ArrowForwardIcon />
                                                </Box>
                                            </Box> */}
                      </Grid>
                    </CngGridItem>

                    <CngGridItem xs={12} sm={5}>
                      <Box
                        className='view-card-content'
                        style={Utils.auditHighLight(auditBooking, [
                          'placeOfDeliveryCode'
                        ])}
                      >
                        <Box
                          className='view-card-content-label'
                          style={{ paddingLeft: 0 }}
                        >
                          {translatedTextsObject.placeOfDelivery}
                        </Box>
                        <Box
                          className='view-card-content-value'
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          {/* using placeOfDeliveryCodeField.value will cause data render issue due to initial field value is empty */}
                          {/* <PortViewField portCode={placeOfDeliveryCodeField.value} /> */}
                          <PortViewField
                            portCode={shipmentData.placeOfDeliveryCode}
                          />
                        </Box>
                      </Box>
                    </CngGridItem>
                  </Grid>
                </CngGridItem>
                <CngGridItem xs={12} sm={4}></CngGridItem>
                <Grid container xs={12} sm={8}>
                  <CngGridItem xs={12} sm={4}>
                    <Box
                      className='view-card-content'
                      style={Utils.auditHighLight(auditBooking, [
                        'cargoReadyDate'
                      ])}
                    >
                      <Box className='view-card-content-label'>
                        {translatedTextsObject.cargoReadyDate}
                      </Box>
                      <Box className='view-card-content-value'>
                        {Utils.formatDate(
                          shipmentData.cargoReadyDate,
                          Utils.FORMAT_DATE,
                          Utils.DASH
                        )}
                      </Box>
                    </Box>
                  </CngGridItem>

                  <CngGridItem xs={2} sm={2}>
                    <Grid
                      container
                      justify='center'
                      alignItems='center'
                      style={{ height: '100%' }}
                    >
                      {/* <Box className="view-card-content">
                                                <Box className="view-card-content-value">
                                                    <ArrowForwardIcon />
                                                </Box>
                                            </Box> */}
                    </Grid>
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                    <Box
                      className='view-card-content'
                      style={Utils.auditHighLight(auditBooking, [
                        'cargoDeliveryDate'
                      ])}
                    >
                      <Box className='view-card-content-label'>
                        {translatedTextsObject.cargoDeliveryDate}
                      </Box>
                      <Box className='view-card-content-value'>
                        {Utils.formatDate(
                          shipmentData.cargoDeliveryDate,
                          Utils.FORMAT_DATE,
                          Utils.DASH
                        )}
                      </Box>
                    </Box>
                  </CngGridItem>
                </Grid>
              </Grid>
            </ComponentWrapper>

            {showDetails && (
              <div>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs sm></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObject.placeOfReceiptDesc}
                      value={shipmentData.placeOfReceipt}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, [
                          'placeOfReceipt'
                        ]),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObject.placeOfDeliveryDesc}
                      value={shipmentData.placeOfDelivery}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, [
                          'placeOfDelivery'
                        ]),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                </Grid>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs sm></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObject.placeOfReceiptETD}
                      value={Utils.formatDate(
                        shipmentData.placeOfReceiptEtd,
                        Utils.UI_FORMAT_DATE_TIME,
                        Utils.DASH
                      )}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, [
                          'placeOfReceiptEtd'
                        ]),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObject.placeOfDeliveryETA}
                      value={Utils.formatDate(
                        shipmentData.placeOfDeliveryEta,
                        Utils.UI_FORMAT_DATE_TIME,
                        Utils.DASH
                      )}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, [
                          'placeOfDeliveryEta'
                        ]),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                </Grid>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs sm></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObject.destinationCode}
                      value={shipmentData.destCode}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, ['destCode']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObject.finalDestination}
                      value={shipmentData.finalDest}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, ['finalDest']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                </Grid>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs sm></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObject.ladenCutoffDate}
                      value={Utils.formatDate(
                        shipmentData.ladenCutoffDate,
                        Utils.UI_FORMAT_DATE_TIME,
                        Utils.DASH
                      )}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, [
                          'ladenCutoffDate'
                        ]),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObject.siCutoffDate}
                      value={Utils.formatDate(
                        shipmentData.siCutoffDate,
                        Utils.UI_FORMAT_DATE_TIME,
                        Utils.DASH
                      )}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, [
                          'siCutoffDate'
                        ]),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                </Grid>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs sm></CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <LabelValueVertical
                      label={translatedTextsObject.evgmCutoffDate}
                      value={Utils.formatDate(
                        shipmentData.evgmCutoffDate,
                        Utils.UI_FORMAT_DATE_TIME,
                        Utils.DASH
                      )}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, [
                          'evgmCutoffDate '
                        ]),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                </Grid>
                <Grid container xs={12} sm={12} spacing={0}>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                  <CngGridItem xs={8} sm={8}>
                    <LabelValueVertical
                      label={translatedTextsObject.shipperRemarks}
                      value={shipmentData.shipperRemarks}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, ['shpRemarks']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}></CngGridItem>
                  <CngGridItem xs={8} sm={8}>
                    <LabelValueVertical
                      label={translatedTextsObject.carrierRemarks}
                      value={shipmentData.carrierRemarks}
                      boxStyle={{
                        ...Utils.auditHighLight(auditBooking, ['carRemarks']),
                        display: 'inline-block'
                      }}
                    />
                  </CngGridItem>
                </Grid>
                {(moveType === 'PTP' || moveType === 'PTD') && (
                  <PickupDetailComponent
                    label={translatedTextsObject.emptyPickup}
                    companyName={shipmentData.emptyPickupCompName}
                    companyAddress={shipmentData.emptyPickupCompAddress}
                    contactName={shipmentData.emptyPickupContactName}
                    contactPhoneNo={shipmentData.emptyPickupContactPhoneNo}
                    timeLabel1={translatedTextsObject.emptyPickupDateTime}
                    time1={shipmentData.emptyPickupDatePickup}
                    styleCompany={{
                      ...Utils.auditHighLight(auditBooking, [
                        'emptyPickupCompName',
                        'emptyPickupCompAddress'
                      ]),
                      display: 'inline-block'
                    }}
                    styleContact={{
                      ...Utils.auditHighLight(auditBooking, [
                        'emptyPickupContactName',
                        'emptyPickupContactName'
                      ]),
                      display: 'inline-block'
                    }}
                    styleTime1={{
                      ...Utils.auditHighLight(auditBooking, [
                        'emptyPickupDatePickup'
                      ]),
                      display: 'inline-block'
                    }}
                  />
                )}
                {(moveType === 'DTP' || moveType === 'DTD') && (
                  <PickupDetailComponent
                    label={translatedTextsObject.shipFrom}
                    companyName={shipmentData.shipFromCompName}
                    companyAddress={shipmentData.shipFromCompAddress}
                    contactName={shipmentData.shipFromContactName}
                    contactPhoneNo={shipmentData.shipFromContactPhoneNo}
                    timeLabel1={translatedTextsObject.shipFromDatePositioning}
                    time1={shipmentData.shipFromDatePositioning}
                    timeLabel2={translatedTextsObject.fullPickupDate}
                    time2={shipmentData.shipFromDatePickup}
                    styleCompany={{
                      ...Utils.auditHighLight(auditBooking, [
                        'shipFromCompName',
                        'shipFromCompAddress'
                      ]),
                      display: 'inline-block'
                    }}
                    styleContact={{
                      ...Utils.auditHighLight(auditBooking, [
                        'shipFromContactName',
                        'shipFromContactPhoneNo'
                      ]),
                      display: 'inline-block'
                    }}
                    styleTime1={{
                      ...Utils.auditHighLight(auditBooking, [
                        'shipFromDatePositioning'
                      ]),
                      display: 'inline-block'
                    }}
                    styleTime2={{
                      ...Utils.auditHighLight(auditBooking, [
                        'shipFromDatePickup'
                      ]),
                      display: 'inline-block'
                    }}
                  />
                )}
                {(moveType === 'PTD' || moveType === 'DTD') && (
                  <PickupDetailComponent
                    label={translatedTextsObject.shipTo}
                    companyName={shipmentData.shipToCompName}
                    companyAddress={shipmentData.shipToCompAddress}
                    contactName={shipmentData.shipToContactName}
                    contactPhoneNo={shipmentData.shipToContactPhoneNo}
                    timeLabel1={translatedTextsObject.shipToDateDelivery}
                    time1={shipmentData.shipToDateDelivery}
                    styleCompany={{
                      ...Utils.auditHighLight(auditBooking, [
                        'shipToCompName',
                        'shipToCompAddress'
                      ]),
                      display: 'inline-block'
                    }}
                    styleContact={{
                      ...Utils.auditHighLight(auditBooking, [
                        'shipToContactName',
                        'shipToContactPhoneNo'
                      ]),
                      display: 'inline-block'
                    }}
                    styleTime1={{
                      ...Utils.auditHighLight(auditBooking, [
                        'shipToDateDelivery'
                      ]),
                      display: 'inline-block'
                    }}
                  />
                )}
                <DropoffDetailComponent
                  label={translatedTextsObject.emptyDropoff}
                  dropOffCompanyName={shipmentData.emptyDropoffCompName}
                  dropOffCompanyAddress={shipmentData.emptyDropoffCompAddress}
                  dropOffContactName={shipmentData.emptyDropoffContactName}
                  dropOffContactPhoneNo={shipmentData.emptyDropoffContactPhoneNo} th
                  dropOffTimeLabel1={translatedTextsObject.emptyDropoffDateTime}
                  dropOffTime1={shipmentData.emptyDropoffDateDropoff}
                  styleCompany={{
                    ...Utils.auditHighLight(auditBooking, [
                      'emptyDropoffCompName',
                      'emptyDropoffCompAddress'
                    ]),
                    display: 'inline-block'
                  }}
                  styleContact={{
                    ...Utils.auditHighLight(auditBooking, [
                      'emptyDropoffContactName',
                      'emptyDropoffContactName'
                    ]),
                    display: 'inline-block'
                  }}
                  styleTime1={{
                    ...Utils.auditHighLight(auditBooking, [
                      'emptyDropoffDateDropoff'
                    ]),
                    display: 'inline-block'
                  }}
                />
              </div>
            )}
          </Box>
        </Card>
      </CngGridItem>
    </Grid>
  )
}

const initialValues = Object.freeze({
  moveType: 'PTP' //myMoveType,
})

const ViewShipmentComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewShipmentComponent
